import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Strong, Icon, LinkBox, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { IoIosArrowRoundForward } from "react-icons/io";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Trailblaze24
			</title>
			<meta name={"description"} content={"Trailblaze24 пропонує оренду квадроциклів для тих, хто цінує свободу, екстрим та незабутні емоції."} />
			<meta property={"og:title"} content={"Trailblaze24"} />
			<meta property={"og:description"} content={"Trailblaze24 пропонує оренду квадроциклів для тих, хто цінує свободу, екстрим та незабутні емоції."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6745953f28b5e30023ae7079/images/BRP-Can-Am-Outlander-MAX-570.png?v=2024-11-27T09:00:38.548Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6745953f28b5e30023ae7079/images/BRP-Can-Am-Outlander-MAX-570.png?v=2024-11-27T09:00:38.548Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6745953f28b5e30023ae7079/images/BRP-Can-Am-Outlander-MAX-570.png?v=2024-11-27T09:00:38.548Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6745953f28b5e30023ae7079/images/BRP-Can-Am-Outlander-MAX-570.png?v=2024-11-27T09:00:38.548Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6745953f28b5e30023ae7079/images/BRP-Can-Am-Outlander-MAX-570.png?v=2024-11-27T09:00:38.548Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6745953f28b5e30023ae7079/images/BRP-Can-Am-Outlander-MAX-570.png?v=2024-11-27T09:00:38.548Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6745953f28b5e30023ae7079/images/BRP-Can-Am-Outlander-MAX-570.png?v=2024-11-27T09:00:38.548Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="text" />
		</Components.Header>
		<Section background="--color-darkL2" padding="60px 0" sm-padding="40px 0">
			<Box
				margin="-16px -16px -16px -16px"
				padding="0px 0px 0px 0px"
				display="flex"
				width=" "
				flex-wrap="wrap"
			>
				<Box padding="16px 16px 16px 16px" display="flex" width="50%" lg-width="100%">
					<Box
						padding="98px 64px 98px 64px"
						display="flex"
						flex-direction="column"
						background="rgba(247, 251, 255, 0.34)"
						color="--dark"
						justify-content="center"
					>
						<Text
							as="h4"
							margin="12px 0"
							font="--base"
							color="--lightD2"
							letter-spacing="1px"
							text-transform="uppercase"
						>
							Готові до незабутніх пригод?
						</Text>
						<Text
							as="h2"
							margin="12px 0"
							font="--headline2"
							md-font="--headline3"
							color="--light"
						>
							Trailblaze24 пропонує оренду квадроциклів для тих, хто цінує свободу, екстрим та незабутні емоції.
						</Text>
					</Box>
				</Box>
				<Box display="flex" width="50%" flex-wrap="wrap" lg-width="100%">
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="100%"
						lg-width="33.3333%"
						md-width="100%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 624px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://uploads.quarkly.io/6745953f28b5e30023ae7079/images/img_9036-1024x706.jpg?v=2024-11-27T09:00:38.534Z) center/cover no-repeat"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="50%"
						lg-width="33.3333%"
						md-width="50%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 296px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://uploads.quarkly.io/6745953f28b5e30023ae7079/images/BRP-Outlander-XMR-1000R.png?v=2024-11-27T09:00:38.544Z) center/contain no-repeat"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="50%"
						lg-width="33.3333%"
						md-width="50%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 296px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://uploads.quarkly.io/6745953f28b5e30023ae7079/images/BRP-Can-Am-Outlander-MAX-570%20%281%29.png?v=2024-11-27T09:00:38.541Z) center/contain no-repeat"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section
			padding="100px 0 120px 0"
			background="--color-dark"
			color="#000000"
			sm-padding="80px 0 90px 0"
			quarkly-title="Statistics-5"
		>
			<Text margin="0px 0px 0px 0px" color="--light" font="--headline2">
				Ми допоможемо вам втекти від міського шуму та поринути у красу дикої природи, відкриваючи нові горизонти та отримуючи справжнє задоволення від кожної хвилини.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				margin="50px 0px 0px 0px"
				lg-flex-direction="column"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="#c0cad8"
					font="normal 300 18px/1.5 --fontFamily-sans"
					padding="0px 25px 0px 0px"
					width="50%"
					lg-width="100%"
					lg-padding="0px 0 30px 0px"
				>
					Оренда квадроциклів — це не просто розвага, це ваш квиток до свободи та захоплюючих пригод. Ми створені для того, щоб допомогти вам відчути драйв та єднання з природою. Наша команда пропонує найкращі можливості для активного відпочинку: від швидкісних заїздів лісовими стежками до неспішних прогулянок мальовничими гірськими краєвидами.
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="#c0cad8"
					font="normal 300 18px/1.5 --fontFamily-sans"
					padding="0px 0px 0px 25px"
					width="50%"
					lg-padding="0px 0px 0px 0"
					lg-width="100%"
				>
					Наша місія — дарувати вам незабутні емоції, залишаючи яскраві спогади від кожної подорожі. Ми пропонуємо сучасну техніку, ретельно перевірену перед кожним виїздом, і забезпечуємо повний супровід, щоб ви могли насолоджуватися пригодою без жодних турбот.
				</Text>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				margin="80px 0px 0px 0px"
				display="grid"
				grid-template-columns="repeat(4, 1fr)"
				md-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-rows="auto"
				md-margin="50px 0px 0px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					border-width="0 0 0 1px"
					border-style="solid"
					border-color="rgba(88, 109, 142, 0.5)"
					padding="0px 0px 0px 25px"
					md-margin="0px 0px 25px 0px"
					sm-padding="0px 0px 0px 18px"
				>
					<Text margin="0px 0px 0px 0px" color="--light" font="normal 700 46px/1.2 --fontFamily-sans" sm-font="normal 700 38px/1.2 --fontFamily-sans">
						2020
					</Text>
					<Text margin="15px 0px 0px 0px" color="#c0cad8" font="--lead" sm-margin="8px 0px 0px 0px">
						засновані
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					border-width="0 0 0 1px"
					border-style="solid"
					border-color="rgba(88, 109, 142, 0.5)"
					padding="0px 0px 0px 25px"
					md-margin="0px 0px 25px 0px"
					sm-padding="0px 0px 0px 18px"
				>
					<Text margin="0px 0px 0px 0px" color="--light" font="normal 700 46px/1.2 --fontFamily-sans" sm-font="normal 700 38px/1.2 --fontFamily-sans">
						1000+
					</Text>
					<Text margin="15px 0px 0px 0px" color="#c0cad8" font="--lead" sm-margin="8px 0px 0px 0px">
						щасливих клієнтів
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					border-width="0 0 0 1px"
					border-style="solid"
					border-color="rgba(88, 109, 142, 0.5)"
					padding="0px 0px 0px 25px"
					sm-padding="0px 0px 0px 18px"
				>
					<Text margin="0px 0px 0px 0px" color="--light" font="normal 700 46px/1.2 --fontFamily-sans" sm-font="normal 700 38px/1.2 --fontFamily-sans">
						7
					</Text>
					<Text margin="15px 0px 0px 0px" color="#c0cad8" font="--lead" sm-margin="8px 0px 0px 0px">
						партнерів
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					border-width="0 0 0 1px"
					border-style="solid"
					border-color="rgba(88, 109, 142, 0.5)"
					padding="0px 0px 0px 25px"
					sm-padding="0px 0px 0px 18px"
				>
					<Text margin="0px 0px 0px 0px" color="--light" font="normal 700 46px/1.2 --fontFamily-sans" sm-font="normal 700 38px/1.2 --fontFamily-sans">
						30+
					</Text>
					<Text margin="15px 0px 0px 0px" color="#c0cad8" font="--lead" sm-margin="8px 0px 0px 0px">
						квадроциклів на вибір
					</Text>
				</Box>
			</Box>
		</Section>
		<Section
			padding="60px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			background="--color-dark"
			quarkly-title="Advantages/Features-10"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
				lg-justify-content="flex-start"
			>
				<Text
					as="h1"
					margin="0px"
					font="--headline2"
					color="--light"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="left"
				>
					Чому обирають нас?
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="32px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="48px"
			>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--lead" color="--light" margin="0px 0px 6px 0px">
							<Strong>
								Сучасна техніка.
							</Strong>
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Наші квадроцикли регулярно обслуговуються, щоб гарантувати комфорт і безпеку.
						</Text>
					</Box>
					<LinkBox
						flex-direction="row"
						align-items="center"
						justify-content="flex-start"
						color="--red"
						hover-opacity="0.7"
						transition="--opacityOut"
						href="/poslugi"
					>
						<Text margin="0px 0px 0px 0px" letter-spacing="0.3px">
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								До послуг
							</Strong>
						</Text>
						<Icon category="io" icon={IoIosArrowRoundForward} size="24px" />
					</LinkBox>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--lead" color="--light" margin="0px 0px 6px 0px">
							<Strong>
								Професійний підхід
							</Strong>
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Перед заїздом кожен учасник отримує інструктаж від наших досвідчених фахівців.
						</Text>
						<LinkBox
							flex-direction="row"
							align-items="center"
							justify-content="flex-start"
							color="--red"
							hover-opacity="0.7"
							transition="--opacityOut"
							href="/poslugi"
						>
							<Text margin="0px 0px 0px 0px" letter-spacing="0.3px">
								<Strong
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									До послуг
								</Strong>
							</Text>
							<Icon category="io" icon={IoIosArrowRoundForward} size="24px" />
						</LinkBox>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--lead" color="--light" margin="0px 0px 6px 0px">
							<Strong>
								Індивідуальні маршрути
							</Strong>
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Ми допоможемо підібрати оптимальний маршрут залежно від ваших побажань та рівня підготовки.
						</Text>
					</Box>
					<LinkBox
						flex-direction="row"
						align-items="center"
						justify-content="flex-start"
						color="--red"
						hover-opacity="0.7"
						transition="--opacityOut"
						href="/poslugi"
					>
						<Text margin="0px 0px 0px 0px" letter-spacing="0.3px">
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								До послуг
							</Strong>
						</Text>
						<Icon category="io" icon={IoIosArrowRoundForward} size="24px" />
					</LinkBox>
				</Box>
			</Box>
		</Section>
		<Section padding="24px 0 60px 0" md-padding="30px 0 30px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="45%"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				md-margin="0px 0px 30px 0px"
				empty-min-width="64px"
				empty-min-height="64px"
				md-display="flex"
			>
				<Image
					width="450px"
					height="450px"
					src="https://uploads.quarkly.io/6745953f28b5e30023ae7079/images/sportsman-touring-850-2_cr.jpg?v=2024-11-27T09:00:38.564Z"
					lg-width="320px"
					lg-height="320px"
					srcSet="https://smartuploads.quarkly.io/6745953f28b5e30023ae7079/images/sportsman-touring-850-2_cr.jpg?v=2024-11-27T09%3A00%3A38.564Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6745953f28b5e30023ae7079/images/sportsman-touring-850-2_cr.jpg?v=2024-11-27T09%3A00%3A38.564Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6745953f28b5e30023ae7079/images/sportsman-touring-850-2_cr.jpg?v=2024-11-27T09%3A00%3A38.564Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6745953f28b5e30023ae7079/images/sportsman-touring-850-2_cr.jpg?v=2024-11-27T09%3A00%3A38.564Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6745953f28b5e30023ae7079/images/sportsman-touring-850-2_cr.jpg?v=2024-11-27T09%3A00%3A38.564Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6745953f28b5e30023ae7079/images/sportsman-touring-850-2_cr.jpg?v=2024-11-27T09%3A00%3A38.564Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6745953f28b5e30023ae7079/images/sportsman-touring-850-2_cr.jpg?v=2024-11-27T09%3A00%3A38.564Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Text font="--headline4" lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif" margin="0px 0px 0px 0px" color="--green">
					Наші послуги доступні цілий рік, незалежно від погодних умов. Кожен сезон додає свої фарби та враження до ваших пригод.
				</Text>
				<LinkBox
					flex-direction="row"
					align-items="center"
					justify-content="flex-start"
					color="--red"
					hover-opacity="0.7"
					transition="--opacityOut"
					href="/poslugi"
					margin="2rem 0px 0px 0px"
				>
					<Text margin="0px 0px 0px 0px" letter-spacing="0.3px">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							До послуг
						</Strong>
					</Text>
					<Icon category="io" icon={IoIosArrowRoundForward} size="24px" />
				</LinkBox>
			</Box>
			<Box
				flex-direction="column"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="55%"
				display="flex"
				justify-content="center"
				md-width="100%"
			>
				<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
					<Box display="flex" align-items="center" width="100%" justify-content="flex-end">
						<Text font="--headline4" lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif" margin="0px 0px 0px 0px" color="--green">
							Оренда квадроциклів для індивідуальних заїздів та корпоративних заходів.
						</Text>
					</Box>
				</Box>
				<Box align-items="center" margin="0px 0px 16px 0px" display="flex">
					<Box display="flex" align-items="center" width="100%" justify-content="flex-end">
						<Text color="--green" font="--headline4" lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif" margin="0px 0px 0px 0px">
							Проведення майстер-класів для новачків.
						</Text>
					</Box>
				</Box>
				<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
					<Box display="flex" align-items="center" width="100%" justify-content="flex-end">
						<Text margin="0px 0px 0px 0px" color="--green" font="--headline4" lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif">
							Організацію тематичних турів у різних регіонах.
						</Text>
					</Box>
				</Box>
				<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
					<Box display="flex" align-items="center" width="100%" justify-content="flex-end">
						<Text lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif" margin="0px 0px 0px 0px" color="--green" font="--headline4">
							Маршрути різної складності: від легких прогулянок до екстремальних заїздів.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footernew>
			<Override slot="text" />
			<Override slot="text1" />
			<Override slot="link4" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
			<Override slot="box3" />
		</Components.Footernew>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"673f21f141a0cf0024a5b026"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});